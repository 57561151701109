<script setup>
import HiddenInputComponent from './hidden-input-component.vue';
  const props = defineProps({ question: { type: Object }, 
                              options: { type: Object },
                              hiddenInput: { type: Object } })
  function getId(opt) {
    return `passion_assessment_answers_attributes__passion_assessment_question_option_id_${opt.id}`
  }

  function isFirstOption(index) {
    return index === 0
  }

  function isLastOption(index, total) {
    return index === total - 1
  }

</script>

<template>
  <p class="margin-0 margin-20-bottom smaller-line-height">
    <small>Choose one.</small>
  </p>
  <div class="row form-custom-colors">
    <div v-for="(option, index) in options" :key="option.id" class="col s2 input-field radio-box-container margin-0-bottom">
      <HiddenInputComponent
        v-if="hiddenInput"
        :input-id="hiddenInput.id"
        :input-value="hiddenInput.value"
        :input-name="hiddenInput.name"
      />
      <div class="radio-box no-check fill-check margin-10-bottom">
        <label :for="getId(option)">
          <input
            :id="getId(option)"
            type="radio"
            class="radio-box-value"
            name="passion_assessment[answers_attributes][][passion_assessment_question_option_id]"
            :value="option.id"
            style="pointer-events:unset; position: relative;"
          >
          <span class="padding-0-left">{{ option.option_text }}</span>
        </label>
      </div>
      <span
        v-if="option.option_subtext"
        class="helper-text" 
        :class="{'margin-10-left' : isFirstOption(index), 'right-align' : isLastOption(index, options.length)}" 
        style="overflow: visible;"
      >{{ option.option_subtext }}</span>
    </div>
  </div>
</template>
