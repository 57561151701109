<script setup>
import { ref, defineProps } from 'vue'
import HiddenInputComponent from './hidden-input-component.vue';

const props = defineProps({ question: { type: Object }, 
                            options: { type: Array },
                            hiddenInput: { type: Object }, 
                            passionAssessment: { type: Object } })

props.options.forEach((opt) => {
  opt.name = 'passion_assessment[answers_attributes][][passion_assessment_question_option_id]'
  opt.inputId = `passion_assessment_question_option_id_${opt.id}`
})

const activeIndices = ref([])

function toggleBorder(index) {
  activeIndices.value.includes(index) ? removeFromActive(index) : activeIndices.value.push(index)
}

function removeFromActive(index) {
  const idx = activeIndices.value.indexOf(index)
  activeIndices.value.splice(idx, 1)
}

function hasBorder(index) {
  return activeIndices.value.includes(index);
}

</script>

<template>
  <p class="margin-0 margin-20-bottom smaller-line-height">
    <small>Choose as many as you like.</small>
  </p>
  <div class="step-content causes">
    <div class="row margin-negative-10-left">
      <fieldset class="cause-selection-container">
        <div
          v-for="option in options"
          :key="option.id"
          class="cause-option checkbox-cause-container checkbox-cause-container--smaller col s12 m6 l3"
          data-js-matchheight
        >
          <label :class="{ 'cause-selected': hasBorder(option.id) }" @change="toggleBorder(option.id)">
            <div class="label-content">
              <HiddenInputComponent
                v-if="hiddenInput"
                :input-id="hiddenInput.id"
                :input-value="hiddenInput.value"
                :input-name="hiddenInput.name"
              />
              <input
                :id="option.inputId"
                type="checkbox"
                class="checkbox-cause print-cause validate"
                :name="option.name"
                :value="option.id"
              >
              <span class="lifted-checkmark">
                <div class="cause-name" :class="{ 'cause-name-no-subtext': !option.option_subtext }">
                  {{ option.option_text }}
                  <span v-if="option.option_subtext" class="charity-drawer-trigger">{{ option.option_subtext }}</span>
                </div>
              </span>
            </div>
          </label>
        </div>
      </fieldset>
    </div>
  </div>
</template>
