<script setup>
import { ref } from 'vue'
import { destructureExternalProps } from '../vue_utils';
import RadioButtonComponent from './radio-button-component.vue';
import HiddenInputComponent from './hidden-input-component.vue';

const props = defineProps({ 
  question: { type: Object, required: true }, 
  options: { type: Array, required: true }, 
  hiddenInput: { type: Object, required: true },
  passionAssessment: { type: Object, required: true }
})

// See vue_utils.js for an explanation of why this is needed
const { options } = destructureExternalProps(props)

options.value.forEach(o => {
  o.inputId = `passion_assessment_answers_attributes__passion_assessment_question_option_id_${o.id}`
  o.inputName = 'passion_assessment[answers_attributes][][passion_assessment_question_option_id]'
  o.checked = false
})

const radioButtons = ref([]);

// When a checkbox is checked, make sure all other options are unchecked
function onChildUpdate(optionId) {
  options.value.forEach(opt => {
    if (opt.id === optionId) {
      opt.checked = ref(!opt.checked)
    } else {
      opt.checked = ref(false)
    }
  })
}
</script>

<template>
  <p class="margin-0 margin-20-bottom smaller-line-height">
    <small>Choose one.</small>
  </p>
  <div class="step-content causes">
    <div class="margin-negative-10-left">
      <fieldset class="cause-selection-container display-flex justify-flex-start flex-wrap">
        <div
          v-for="option in options"
          :key="option.id"
          ref="radioButtons"
          class="cause-option checkbox-cause-container checkbox-cause-container--smaller hide-check full-width-sm-and-down"
          data-js-matchheight
        >
          <HiddenInputComponent
            v-if="hiddenInput"
            :input-id="hiddenInput.id"
            :input-value="hiddenInput.value"
            :input-name="hiddenInput.name"
          />
          <RadioButtonComponent :option="option" @update="onChildUpdate" />
        </div>
      </fieldset>
    </div>
  </div>
</template>

<style scoped>
  .hide-check {
    width: unset; 
    margin: 10px;
  }
</style>
