<script setup>
const props = defineProps({ option: { type: Object, required: true } })
const emits = defineEmits(['update'])
</script>

<template>
  <label class="full-width-sm-and-down" :class="{ 'cause-selected': option.checked }">
    <div class="label-content">
      <input
        :id="option.inputId"
        :checked="option.checked"
        type="checkbox"
        class="checkbox-cause print-cause validate"
        :name="option.inputName"
        :value="option.id"
        @change="[emits('update', option.id)]"
      >
      <span :class="{ 'lifted-checkmark': !option.option_subtext }">
        <div class="cause-name" :class="{ 'cause-name-no-subtext': !option.option_subtext && option.option_text.length < 15 }">
          {{ option.option_text }}
          <span v-if="option.option_subtext" class="charity-drawer-trigger">{{ option.option_subtext }}</span>
        </div>
      </span>
    </div>
  </label>
</template>

<style scoped>
  .checkbox-cause-container--smaller.checkbox-cause-container.hide-check [type="checkbox"] + span:not(.lever)::before {
    display: none !important;
  }

  label {
    width: fit-content;
    display: flex;
    justify-content: center;
    min-width: 8rem;
  }

  .label-content {
    padding:10px;
    span {
      padding-left: 0;
    }
  }
</style>
